import { useEffect } from 'react';
import { nanoid } from 'nanoid';
import Link from 'next/link';
import { usePathname, useSearchParams } from 'next/navigation';
import { Markdown, useTranslation, lang } from '@/helpers/translate';
import ModalWrapper from '@/helpers/modals/skin1.js';
import { useZusModalStore } from '@/helpers/modal.js';

function MyLink({ children, ...props }) {
  const langCurrent = lang();
  return (
    <Link {...props} locale={langCurrent}>{children[0]}</Link>
  );
}

function Guide(props) {
  const { t } = useTranslation();
  const { setModal1 } = useZusModalStore.getState();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const langCurrent = lang();
  const trad = t('guides:menu', {}, { returnObjects: true });

  useEffect(() => {
    setModal1(null);
  }, [pathname, searchParams]);

  function renderItemList(obj) {
    if (!obj || !obj.children) {
      return null;
    }
    const children = Object.values(obj.children).map((item) => (
      <li className="text-dark hover:text-black" key={item}>
        <Markdown options={{ overrides: { a: { component: MyLink } } }}>{item}</Markdown>
      </li>
    ));
    return (
      <ul className="text-dark leading-7">
        {children}
      </ul>
    );
  }

  function renderLinks() {
    return (
      <nav className="grid grid-cols-1 gap-x-4 md:gap-x-10 md:grid-cols-2 w-80v md:w-full py-6 px-8 md:px-8 md:py-6 text-left text-[15px]">
        {renderItemList(trad?.menu1)}
        {renderItemList(trad?.menu2)}
      </nav>
    );
  }
  function renderGuides() {
    return (
      renderLinks()
    );
  }

  function openGuides() {
    setModal1(
      <ModalWrapper onClose={() => { setModal1(null); }} delay={100}>
        {renderGuides()}
      </ModalWrapper>,
    );
  }

  return (
    <>
      <a href="#" key={nanoid()} {...props} onClick={() => { openGuides(); }}>{t('guides:title')}</a>
      <div className="hidden">{renderLinks()}</div>
    </>
  );
}

export default Guide;
